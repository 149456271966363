import addColonAtPosition from '@/helpers/formatting/addColonAtPosition'
// openingTime/closingTime format 24hr, no leading 0 and no :
export const openingTime = 830
export const closingTime = 1730
export const openingHours = `Monday - Friday: ${addColonAtPosition(openingTime, 1)} to ${addColonAtPosition(closingTime, 2)}`

export const bankHolidays = [
  {
    'title': 'Early May bank holiday',
    'date': '2021-05-03',
    'notes': '',
    'bunting': true
  },
  {
    'title': 'Spring bank holiday',
    'date': '2021-05-31',
    'notes': '',
    'bunting': true
  },
  {
    'title': 'Summer bank holiday',
    'date': '2021-08-30',
    'notes': '',
    'bunting': true
  },
  {
    'title': 'Christmas Day',
    'date': '2021-12-27',
    'notes': 'Substitute day',
    'bunting': true
  },
  {
    'title': 'Boxing Day',
    'date': '2021-12-28',
    'notes': 'Substitute day',
    'bunting': true
  }
]
