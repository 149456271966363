<template>
  <p class="opening-hours-message">
    <slot />
  </p>
</template>
<script>
export default {
  name: 'OpeningHoursMessage'
}
</script>
<style lang="scss">
    .opening-hours-message {
      font-size: 13px;
      color: #152D72;
      letter-spacing: -0.33px;
    }
</style>
