<template>
  <div
    class="d-flex container-nav nav-journey justify-content-between align-items-center container-fluid"
    :class="{'partner-navigation': getPartner}"
  >
    <div 
      v-if="hideLogo"
      class="logo-container"
    >
      <a v-if="!isMoneyTest" href="https://thinkbusinessloans.com/">
        <SvgThinkNew
          class="logo partner-navigation__logo partner-navigation__logo--first partner-navigation__logo--think-new"
        />
      </a>
      <a class="partner-navigation__logo-link">
        <div 
          class="logo partner-navigation__logo partner-navigation__logo--second"
          :class="`${getPartner.name}-width`"
        >
          <component :is="getPartner.logoComponent" v-if="getPartner.logoComponent" />
        </div>
        <img
          v-if="!getPartner.logoComponent"
          :src="require(`@/assets/images/partner-logos/${getPartner.name}.png`)"
          :alt="`${getPartner.name} logo`"
          class="logo partner-navigation__logo partner-navigation__logo--second"
        > 
      </a>     
    </div>

    <div v-else>
      <a href="/">
        <img
          src="@/assets/images/logos/think-logo.svg"
          alt="Bionic"
          class="logo"
        >
      </a>
    </div>
    <div class="d-lg-flex align-items-center phone-dektop">
      <a class="menu-phone-text" :class="telephoneColor" :href="`tel:${telephoneNumber}`">
        <!-- v-if="!isMoneyTest" -->
        <SvgPhoneIcon 
          :fill="[getPartner ? getPartner.color : '#ff6841']"
          class="phone-icon"
        />
        {{ friendlyTelephoneNumber }}
      </a>
      <OpeningHoursMessage class="opening-hours-message-outer" :class="telephoneColor"> 
        {{ openingHours }}
      </OpeningHoursMessage>
    </div>
    <a :href="`tel:${telephoneNumber}`" class="d-lg-none phone-mobile">
      <div>
        <SvgPhoneIcon 
          :fill="[getPartner ? getPartner.color : '#ff6841']"
          class="phone-icon"
        />
      </div>
      
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OpeningHoursMessage from '@/components/shared/OpeningHoursMessage.vue'
import { RECOVERYLOANS, ASSETFINANCE, MONEY } from '@/helpers/constants/partner-names'
import SvgBionic from '@/components/shared/svg/SvgBionic'
import SvgMoney from '@/components/shared/svg/SvgMoney'
import SvgUswitch from '@/components/shared/svg/SvgUswitch'
import SvgForbes from '@/components/shared/svg/SvgForbes'
import SvgMSM from '@/components/shared/svg/SvgMSM'
import SvgThinkNew from '@/components/shared/svg/SvgThinkNew'
import SvgPhoneIcon from '@/components/shared/svg/SvgPhoneIcon'
import { openingHours } from '@/helpers/constants/openingHours'

export default {
  name: 'NavbarJourney',
  components: {
    OpeningHoursMessage,
    SvgBionic,
    SvgMoney,
    SvgUswitch,
    SvgForbes,
    SvgMSM,
    SvgThinkNew,
    SvgPhoneIcon
  },
  props: {
    telephone: {
      type: String,
      required: true
    },
    friendlyTelephone: {
      type: String,
      required: true
    },
    telephoneColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      RECOVERYLOANS,
      MONEY,
      openingHours,
      currentSvg: ''
    }
  },
  computed: {
    ...mapGetters(['getPartner', 'isMoneyTest']),
    telephoneNumber () {
      // use partner telephone if a partner
      if (this.getPartner) {
        return this.getPartner.telephoneNumber
      } else {
      // use number from props  
        return this.telephone
      }
    },
    friendlyTelephoneNumber () {
      // use partner friendly telephone if a partner
      if (this.getPartner) {
        return this.getPartner.telephoneNumber.match(/([0-9]{4})([0-9]{3})([0-9]*)/).splice(1).join(' ')
      } else {
      // use friendly telephone from props
        return this.friendlyTelephone 
      }
    },
    hideLogo () {
      if (!this.getPartner) return false
      if (this.getPartner.name === RECOVERYLOANS || this.getPartner.name === ASSETFINANCE) {
        return false
      } else {
        return true
      }
    },
    nonPartnerBg () {
      if (!this.getPartner) return 'bg-orange'
      return ''
    }
  },
  mounted () {
    const partnerLink = document.querySelector('.partner-navigation__logo-link')
    if (this.getPartner.link) {
      partnerLink.href = this.getPartner.link
      partnerLink.target = '_blank'
    }
  }
}
</script>

<style lang="scss">
.nav-journey {
  .opening-hours-message-outer {
    font-size: 11px;
    position: relative;
    top: -5px;
}
}
</style>

<style lang="scss" scoped>
.logo-container {
  display:flex;
  align-items: center;
}

.circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .phone-icon {
      width: 12px;
    }
}
  .phone-icon {
    width: 15px;
    @media(min-width: 992px) {
      transform: rotate(8deg);
      margin-right: 6px;
      position: absolute;
      left: -23px;
    }

  }
.partner-navigation {
  &__logo {
      @media(min-width: $md-width) {
        width: auto !important;
      }

    &--first {
      border-right: 1px solid #DFE3E5;
      padding-right: 10px;
      width: 110px !important;

      @media(min-width: $md-width) {
      width: 140px !important;
      padding-right: 23px;
      }
    }

    &--think-new {
        height: 35px;
        width: 100px !important;
        padding-right: 10px;
    }

    &--second {
      padding-left: 10px;
      height: auto !important;
      max-width: 185px;
      @media(min-width: $md-width) {
        padding-left: 23px;
      }

      @media (max-width: $mobile-breakpoint-max) {
          height: auto !important;
      }

    }
  }
}

.container-nav {
  background-color: #FFFFFF;
  box-shadow: 0 0 14px 0 rgba(0,0,0,0.1);
  height: 87px;

  @media(max-width: $mobile-breakpoint-max) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  margin-bottom: 40px;
  @media(min-width: $screen-md) {
      margin-bottom: 100px;
  }

}

img.logo {
  margin: 10px 0 10px 0;
  height: 41px;
}

img.phone {
  height: 30px;
  margin-right: 10px;
}

.menu-phone-text {
  color: $main-title-color;
  font-size: 23.04px;
  font-weight: bold;
  letter-spacing: 0;
}

.phone-dektop {
  top: 6px;
  position: relative;
  display: none;
  flex-direction: column;
}

@media (max-width: $mobile-breakpoint-max) {
  img.logo {
    margin: 5px 0;
    height: 35px;
  }
}

.bionic-width {
  padding-left: 15px;
  svg { 
    height: 35px;
    width: 95px !important;
  }

}

.usw-width {
  width: 121px !important;
  @media (min-width: $mobile-breakpoint-max) {
      width: 155px !important;
  }
}

.money-width {
  width: 121px !important;
  @media (min-width: $mobile-breakpoint-max) {
      width: 175px !important;
  }
}
</style>
