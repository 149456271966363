<template>
  <svg
    class="svg-phone-icon"
    height="35"
    viewBox="0 0 25 35"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="m277.435745 59.4503477-3.369286-4.3516304c-1.195553-1.5488854-3.441744-1.807033-4.927128-.5531734l-1.702757 1.4382508c-.362289.2950258-.941951.2581475-1.268011-.0737565-3.188141-3.6140659-5.434332-7.8550617-6.666114-12.5385961-.108687-.4794169.144916-.995712.615891-1.1432249l2.065046-.7006863c1.847673-.6269298 2.825853-2.6552321 2.209962-4.5360215l-1.811444-5.1629514c-.543433-1.5857636-2.354877-2.2864498-3.804033-1.5120071-5.47056 3.0240143-8.803617 9.5883382-1.847672 22.569473s14.201721 13.6449428 19.672281 10.6209284c1.449156-.7744427 1.847673-2.7289885.833265-4.0566046z" :fill="fill" transform="translate(-253 -30)" /></svg>
</template>

<script>
export default {
  name: 'SvgPhoneIcon',
  props: {
    fill: {
      type: Array,
      required: true
    }
  }
}
</script>
